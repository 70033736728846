import {
  Children,
  cloneElement,
  type FC,
  isValidElement,
  ReactElement,
  ReactNode,
  RefObject,
  useState
} from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button, RadiusBottomLeftIcon } from '@workspace/4Z1.uikit.react';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { Portal } from '@/shared/utils';
import { SearchOnAreaStore } from '../model/SearchOnArea.store';
import { Geometry } from 'ol/geom';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';

interface Props extends MapPluginProps {
  readonly target: RefObject<Element>;
  readonly widgetResult: ReactNode;
  readonly widgetTarget?: RefObject<Element>;
}

export const SearchOnArea: FC<Props> = observer(({
    map = implicitMapCheck(),
    target,
    widgetResult,
    widgetTarget,
  }) => {
  const [store] = useState(() => new SearchOnAreaStore(map));
  const intl = useIntl()

  // TODO избавиться от передачи параметров, когда новый компонент карты будет работать на DI.
  const widgetResultWithArea = Children.map(widgetResult, child => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement<{area: Geometry, close: () => void}>, {area: store.area, close: () => store.toggleSearchOnArea() });
    }
    return child;
  });

  return (
    <>
      <Portal target={target}>
        <Tooltip text={intl.formatMessage({ id: 'map.SearchOnArea' })} direction={'bottom-center'}>
          <Button
            onlyIcon
            icon={<RadiusBottomLeftIcon.Small/>}
            onClick={() => store.toggleSearchOnArea()}
            className={classNames({active: store.active})}
          />
        </Tooltip>
      </Portal>

      {
        store.showPanel &&
        <Portal target={widgetTarget}>
          {widgetResultWithArea}
        </Portal>
      }
    </>
  )
})
