import { makeAutoObservable } from 'mobx';
import VectorLayer from 'ol/layer/Vector';
import { LayerOptions, MapEngine } from '@/shared/map/model/interfaces';
import { Draw, Snap } from 'ol/interaction';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { Vector as VectorSource } from 'ol/source';
import { Geometry } from 'ol/geom';
import { DrawEvent } from 'ol/interaction/Draw';

const LAYER_NAME = 'searchOnArea';

export class SearchOnAreaStore {
  public active: boolean = false;
  public showPanel: boolean = false;
  private _sourceLayer: VectorSource = new VectorSource();

  private _draw!: Draw;
  private _area!: Geometry;

  constructor(
    private readonly map: MapEngine,
  ) {
    makeAutoObservable(this);
  }

  get area(): Geometry {
    return this._area;
  }

  private mount() {
    this.active = true;
    const searchOnAreaLayer = new VectorLayer(
      {
        source: this._sourceLayer,
        zIndex: 1,
        name: LAYER_NAME,
      } as LayerOptions
    )

    this.map.addLayer(searchOnAreaLayer);

    this.addInteraction();
  }

  private unmount() {
    this.active = false;
    this.showPanel = false;
    this._sourceLayer.clear();
    this.map.removeInteraction(this._draw);
  }

  public toggleSearchOnArea() {
    if (this.active) {
      this.unmount();
      return;
    }

    this.mount();
  }

  private endDraw(event: DrawEvent) {
    let geom: Feature[] = [];
    geom.push(
      new Feature(
        event.feature.getGeometry()?.clone().transform('EPSG:3857', 'EPSG:4326'),
      ),
    );
    const geoJsonObj = new GeoJSON().writeFeaturesObject(geom);
    this._area = geoJsonObj?.features[0]?.geometry || [];
    this.showPanel = true;
    this.map.removeInteraction(this._draw);
  }

  private addInteraction() {
    this._draw = new Draw({
      source: this._sourceLayer,
      type: 'Polygon'
    });

    const snap = new Snap({source: this._sourceLayer});
    this.map.addInteraction(snap);

    this._draw.on('drawend', (event: DrawEvent) => this.endDraw(event));

    this.map.addInteraction(this._draw);
  }

}
