export default {
  'flight.name': 'Наименование',
  'flight.routes': 'Все маршруты',
  'flight.users': 'Все пользователи',
  'flight.showAll': 'Показать все',
  'flight.edit': 'Редактировать полёт',
  'flight.NewFlight': 'Новый полёт',
  'flight.types': 'Типы',

  'flight.violations': 'Проишествия',
  'flight.list.empty':
    'На этой странице сейчас пусто',
  'flight.list.suggest':
    'Вы можете загрузить вложения самостоятельно',

  'flight.delete.confirm.title': 'Удалить {title}',
  'flight.delete.confirm.body': 'Вы действительно хотите удалить 1 объект?',
  'flight.flights_delete_error':
    'Произошла ошибка во время удаления полёта. Попробуйте ещё раз!',
  'flight.readMore': 'Подробнее',
  'flight.redirect': 'Перейти',
  'flight.downloadKmlTooltip': 'Скачать KML',
  'flight.missingKmlTooltip': 'KML отсутствует',
  'flight.redirectToPage': 'Перейти на страницу полёта',

  'flight.photo-viewer.title': 'Фотография {title}',
  'flight.video-viewer.title': 'Видео {title}',

  'flight.flights_update_success': 'Данные успешно обновлены',
  'flight.flights_update_error': 'Ошибка обновления',
  'flight.empty_new_flight': 'Новый полёт не может быть пустым',

  'flight.NO_FLIGHT': 'Полёт не найден',
  'flight.Edit_Name_Flight': 'Редактирование название полёта',
  'flight.flights_delete_success': 'Полёт успешно удалён',
};
