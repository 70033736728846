import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'umi';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer, Group as LayerGroup, } from 'ol/layer';
import Feature from 'ol/Feature';
import { Style, Text, Stroke, Fill } from 'ol/style';
import { fromLonLat, toLonLat, transformExtent } from 'ol/proj';


import { Extent, containsCoordinate, equals } from 'ol/extent';
import { Snail, getSk42ZoneGridLines, getSnail, gridLines } from '../sk42grid';
import { LineString, Point } from 'ol/geom';
import { Color } from 'ol/color';

export const SK42Grid = (props: any) => {
  const { map } = props;
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);
  const [groupLayer, setGroupLayer] = useState<LayerGroup | undefined>(undefined);
  const [zone, setZone] = useState<number>(0);

  const _title = intl.formatMessage({
    id: 'sk42.grid',
    defaultMessage: 'Grid SK42',
  });

  function gridSK42(): gridLines {
    const viewportExtent: Extent = map.getView().calculateExtent(map.getSize());
    const viewportExtent4326 = transformExtent(viewportExtent, 'EPSG:3857', 'EPSG:4326');

    return getSk42ZoneGridLines(1000, viewportExtent4326);
  }

  useEffect(() => {
    const snailLayer = new VectorLayer({
      source: undefined,
      visible: cookies.layers.sk42grid ? true : false,
      zIndex: 99,
      minZoom: 13,
      className: 'snail'
    });
    const layer = new VectorLayer({
      source: null,
      visible: cookies.layers.sk42grid ? true : false,
      zIndex: 99,
      minZoom: 13,
    } as BaseLayerOptions);
    const group_sk42grid = new LayerGroup({
      title: _title,
      visible: cookies.layers.sk42grid ? true : false,
      name: 'sk42grid',
      className: 'sk42grid',
      layers: [layer],
    } as GroupLayerOptions);

    const onMoveEnd = (e: Event) => {
      e.preventDefault();
      const view = map.getView();
      const zoom = view.getZoom();
      if (group_sk42grid.getVisible() && zoom >= 13) drawLines(gridSK42());
    }

    map.addLayer(group_sk42grid);
    map.addLayer(snailLayer);

    group_sk42grid.on('change', (layer: any) => {
      snailLayer.setVisible(layer.target.getVisible());
    });

    map.on('moveend', onMoveEnd);

    setGroupLayer(group_sk42grid);

    return () => {
      map.un('moveend', onMoveEnd)
      map.removeLayer(group_sk42grid);
      map.removeLayer(snailLayer);
    };
  }, []);

  function drawLines(data: gridLines) {
    if (data && data.grid.length)
    {
      const source = new VectorSource();
      data.grid.map((lonlat: number[]) => {
        const point1 = fromLonLat([lonlat[0], lonlat[1]]);
        const point2 = fromLonLat([lonlat[2], lonlat[3]]);
        const featureLine = new Feature({
          geometry: new LineString([point1, point2]),
        });
        featureLine.setStyle([
          new Style({
            stroke: new Stroke({
              color: 'black',
              width: 2,
            }),
          }),
          new Style({
            stroke: new Stroke({
              color: 'white',
              width: 1,
            }),
          }),
        ]);

        source.addFeature(featureLine);
      });
      source.addFeatures(setLabels(data.bottomLabels, 'bottom'));
      source.addFeatures(setLabels(data.leftLabels, 'left'));
      source.addFeatures(setLabels(data.rightLabels, 'right'));
      source.addFeatures(setLabels(data.topLabels, 'top'));

      const layer: any = getLayer('sk42grid').getLayers().getArray()[0];

      layer.setSource(source);
      if (data.zone != zone) setZone(data.zone);
    }
  }

  function getLayer(layer_name: string) {
    return map.getLayers().getArray().find((layer: any) => layer.className_ == layer_name);
  };

  function removeLayer(layer_name: string) {
    var layer = getLayer(layer_name);
    if (layer) map.removeLayer(layer);
  };

  return <></>;
};

function drawSnailLine(point1: number[], point2: number[], color: Color) {
  const feature = new Feature({
    geometry: new LineString([fromLonLat(point1), fromLonLat(point2)]),
  });
  feature.setStyle([
    new Style({
      stroke: new Stroke({
        color: color,
        width: 2,
      }),
    }),
  ]);
  return feature;
}

function drawSnail(snail: Snail, snailLayer: any) {
  const snailSource = new VectorSource();
  const featureLineVertical1 = drawSnailLine(snail.vertical1Top, snail.vertical1Bottom, [0,0,255,1]);
  const featureLineVertical2 = drawSnailLine(snail.vertical2Top, snail.vertical2Bottom, [0,0,255,1]);
  const featureLinehorizontal1 = drawSnailLine(snail.horizontal1Left, snail.horizontal1Right, [0,255,0,1]);
  const featureLinehorizontal2 = drawSnailLine(snail.horizontal2Left, snail.horizontal2Right, [0,255,0,1]);

  snailSource.addFeatures([featureLineVertical1, featureLineVertical2, featureLinehorizontal1, featureLinehorizontal2]);
  snailLayer.setSource(snailSource);
}

function setLabels(labels: {coordinate: number[], text: string}[], position: 'top' | 'bottom' | 'left' | 'right'): Feature[] {
  const points: Feature[] = [];
  labels.map((label) => {
    const featurePoint = new Feature({
      geometry: new Point(fromLonLat(label.coordinate)),
    });
    let offsetX = 0;
    if (position == 'left') offsetX = 15;
    if (position == 'right') offsetX = -15;
    let offsetY = 0;
    if (position == 'bottom') offsetY = -15;
    if (position == 'top') offsetY = 15;
    featurePoint.setStyle(
      new Style({
      text: new Text({
        text: label.text,
        font: 'bold 22px Segoe UI',
        fill: new Fill({
          color: "black",
        }),
        stroke: new Stroke({
          color: 'white',
          width: 3,
        }),
        offsetX: offsetX,
        offsetY: offsetY,
      }),
    })
    ),
    points.push(featurePoint);
  });
  return points;
}
