import { FC, useEffect } from 'react';
import { isNil } from 'lodash';
import { dep, diInject } from '@/HOC';
import { useIntl } from 'react-intl';
import { Group as LayerGroup } from 'ol/layer';
import {
  FIELD_OF_VIEW_LAYER,
  implicitMapCheck,
  Layers,
  MapPluginProps,
  useLayersVisibility
} from '@/shared/map';
import { LayerOptions } from '@/shared/map';
import { FieldOfViewLayerStore } from '@/entities/map';
import AssetsIcons from './AssetsIcons';
import { OnlinesLayerDataSource } from '../model/interfaces';


interface Props extends MapPluginProps {
  readonly dataSource: OnlinesLayerDataSource | undefined;
  readonly fieldOfViewLayerStore: FieldOfViewLayerStore;
  readonly showName?: boolean;
}

const OnlinesLayer: FC<Props> = ({
  map = implicitMapCheck(),
  fieldOfViewLayerStore,
  dataSource,
  showName,
}) => {
  const LAYER_NAME = Layers.Onlines;

  const [layerVisibility, fieldOfViewLayer] = useLayersVisibility([Layers.Onlines,  FIELD_OF_VIEW_LAYER])
  const intl = useIntl();

  // Тут мы создаем пустые слои, для Онлайн меток и полей Видимости.
  // Это делается для того, чтобы наш LayerPanel знал о слоях, которые строятся на Оверлеях. Для управления их видимостью.
  useEffect(() => {
    const onlinesGroupLayer = new LayerGroup({
      title: intl.formatMessage({id: 'module.onlines'}),
      name: LAYER_NAME,
      visible: layerVisibility,
    } as LayerOptions);

    map.addLayer(onlinesGroupLayer);

    const fieldOfViewAreaGroupLayer = new LayerGroup({
      title: intl.formatMessage({id: 'online.fieldOfView'}),
      name: FIELD_OF_VIEW_LAYER,
      visible: fieldOfViewLayer,
    } as LayerOptions);

    map.addLayer(fieldOfViewAreaGroupLayer);


    return () => {
      map.removeLayerByName(LAYER_NAME);
      map.removeLayerByName(FIELD_OF_VIEW_LAYER);
    }
  }, []);

  useEffect(() => {
    fieldOfViewLayerStore.setAssets(dataSource?.assetsList ?? []);
  }, [dataSource?.assetsList]);


  return <AssetsIcons
    map={map}
    items={dataSource?.assetsList}
    showLayer={layerVisibility}
    activeItem={dataSource?.selectedItem}
    showName={showName}
  />
}
export default diInject(OnlinesLayer, {
  fieldOfViewLayerStore: dep(FieldOfViewLayerStore.diKey),
});
