import './styles.scss';

import { FC } from 'react';

import { FileItem } from '../model/interface';
import Item from './Items/Item';
import classNames from 'classnames';
import { Badge, CloseIcon } from '@workspace/4Z1.uikit.react';
import { Button } from '@workspace/4Z1.uikit.react';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';
import { Skeleton } from '@workspace/4Z1.uikit.react';


interface Props {
  readonly files: FileItem[];
  readonly title?: string;
  readonly errorMessage?: string;
  readonly size?: 'small' | 'big';
  readonly badgeCount?: number;
  readonly onCloseList?: () => void;
}

const FileList: FC<Props> = ({ files, title, size, badgeCount, errorMessage, onCloseList }) => {
  const intl = useIntl();

  return (
    <div className={classNames('uploadFileListContainer', size)}>
      {title && (
         <div className='uploadFileListHeader'>
          <div>
            <div className='fileListTitleContainer'>
              <h4 className='uploadFileListTitle'>{title}</h4>
              <Badge label={Number(badgeCount)} />
            </div>
            {errorMessage && <p className='errorMessage'>{errorMessage}</p>}
          </div>
          {onCloseList && (
            <Tooltip theme='light' text={intl.formatMessage({ id: 'button.DeleteAll' })} direction='top-center'>
              <Button type='badge' onlyIcon onClick={onCloseList} icon={<CloseIcon.Small />} />
            </Tooltip>
          )}
        </div>
      )}
      {
      files.length > 0 ?
        files.map((file, index) => (
          <Item
            key={index}
            file={file}
            size={size}
          />
        ))
        :
        Array.from({ length: 10 }).map((_, index) => (
          <Skeleton key={index} loading={true} className='loader' />
        ))
      }
    </div>
  );
};

export default FileList;
