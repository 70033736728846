import { FC, RefObject, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button, LayersIcon } from '@workspace/4Z1.uikit.react';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { Portal } from '@/shared/utils';
import { LayersPanelStore } from '../model/LayersPanel.store';
import { LayersPanelDialog } from './LayersPanelDialog';
import { LayersPanelPosition } from '../model/interfaces';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';

interface Props extends MapPluginProps {
  readonly target: RefObject<Element>;
  readonly position?: LayersPanelPosition;
}

export const LayersPanel: FC<Props> = observer((
  {
    map = implicitMapCheck(),
    target,
    position
  }
) => {

  const [store] = useState(() => new LayersPanelStore(map));
  const intl = useIntl();

  useEffect(() => {
    // TODO Придумать способ получения всех слоев, когда карта будет полностью загружена
    store.mount();
  }, [store.show]);

  return (
    <>
      {/* Кнопка Панель слоев */}
      <Portal target={target}>
        <Tooltip text={intl.formatMessage({ id: 'menu.Tiles' })} direction={'bottom-center'}>
          <Button
            className={classNames({active: store.show})}
            onlyIcon
            icon={<LayersIcon/>}
            onClick={() => store.show = !store.show}
          />
        </Tooltip>
      </Portal>

      {/* Панель слоев */}
      {store.show &&
        <LayersPanelDialog
          store={store}
          position={position}
          parentTarget={target.current}
        />
      }
    </>
  )
})
