export default {
  'massload.BACK_TO_UPLOAD': 'К загрузке файлов',
  'massload.File Name': 'Имя файла',
  'massload.Status': 'Статус',
  'massload.FileSize': 'Размер файла',
  'massload.Message': 'Сообщение',
  'massload.Created At': 'Создано',
  'massload.Type': 'Тип файла',
  'massload.Actions': 'Действия',
  'massload.DownloadServiceLink': 'Скачать агентa загрузки',
  'massload.NoService': 'Агент не активен',
  'massload.spaceinfo': 'Дисковое пространство',
  'massload.loading.telemetry': 'Загрузка телеметрии',
  'massload.loading.stop': 'Остановить загрузку',
  'massload.loading.remove': 'Удалить из списка',
  'massload.loading': 'Загрузка: {progress}%',
  'massload.dndFiles': 'Перетащите файлы сюда',
  'massload.dndFilesOnButton': 'Перетащите файлы сюда или нажмите на кнопку',
  'massload.dndFilesOr': 'Перетащите файлы или',
  'massload.upload': 'загрузите',
  'massload.supportedFormats': 'Поддерживаемые форматы: ',
  'massload.selectFile': 'Выбрать каталог',
  'massload.goToUploads': 'Перейти к загрузкам',

  'massload.uploadUSB': 'Загрузка по usb',
  'massload.uploadByChoice': 'Загрузка по выбору',

  'massload.error.fileAlreadyExists': 'Файл уже существует',
  'massload.error.fileTypeNotSupported': 'Тип файла не поддерживается',
  'massload.error.fileTransferFailed': 'Ошибка передачи файла в оперативное хранилище',
  'massload.error.notEnoughSpace': 'Недостаточно свободного места в хранилище',
  'massload.error.fileGroupNotAdded': 'Группа файлов не добавлена',
  'massload.error.spaceCheckError': 'Ошибка проверки доступного места для загрузки',
  'massload.error.postProcessingFailed': 'Ошибка пост-обработки',
  'massload.error.fileInfoNotFound': 'Информация о файле не найдена',
  'massload.error.bearerTokenRequired': 'Требуется токен пользователя',
  'massload.error.fileInfoNotUpdated': 'Информация о файле не обновлена',
  'massload.error.fileArchResultNotUpdated': 'Результат архивирования файла не обновлен',
  'massload.error.internalError': 'Внутренняя ошибка сервера',
  'massload.error.unknownError': 'Неизвестная ошибка',
  'massload.error.networkError': 'Ошибка сети',

  'massload.error.Error': 'Ошибка',
  'massload.error.Terminated': 'Завершено',
  'massload.error.Finished': 'Завершено',
  'massload.error.Uploading': 'Загрузка',
  'massload.error.Stored': 'Сохранено',
  'massload.error.CreateUpload': 'Загрузка создана',
};

