import './styles.scss';

import { useEffect, useMemo, type FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, ClockIcon } from '@workspace/4Z1.uikit.react';
import { Asset, Assets } from '@/entities/assets/model';
import { AssetCardProps } from '@/widgets';
import { useState } from 'react';
import { PopUpList, Badge, Tooltip } from '@workspace/4Z1.uikit.react';

interface Props extends Omit<AssetCardProps, 'actions'> {
  readonly onActivate: (asset: Asset, period: number) => void,
}

const OPTIONS = [
  { value: 1, msgid: 'online.assetCard.last1h'},
  { value: 8, msgid: 'online.assetCard.last8h'},
  { value: 24, msgid: 'online.assetCard.last24h'},
];

/**
 * Построение маршрута у объекта в AssetCard
 * @param asset
 */
export const MovementTrack: FC<Props> = ({asset, onActivate}) => {
  const intl = useIntl();
  const [period, setPeriod] = useState<number | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
 
  const transformedOptions = useMemo(() => {
    return OPTIONS.map(option => ({
      id: option.value,
      label: intl.formatMessage({ id: option.msgid })
    }));
  }, [OPTIONS, intl]);

  const handlePeriod = (key: number) => {
    setPeriod(key);
    onActivate(asset, key);
    setIsOpen(false);
  };

  useEffect(()=>{
    setPeriod(undefined);
  },[asset.id])

  if (!Assets.hasMovementTrack(asset)) {
    return <></>;
  };

  return (
    <div className='movementTrack'>
      <Tooltip title={intl.formatMessage({id: 'online.assetCard.showTrack'})} className={'movementTrackTooltip'}>
        <Button size='small' type={'badge'} onClick={()=>setIsOpen(!isOpen)} className={'movementTrackButton'} active={isOpen}>
          <div className='movementTrackButtonContainer'>
            <ClockIcon.Small/>
            <Badge label={period}></Badge>
          </div>
        </Button>
      </Tooltip>
      <div>
        <PopUpList
          list={transformedOptions}
          isOpen={isOpen}
          onClick={(value) => handlePeriod(value.id)}
          className={'movementTrackPopup'}
          radio={true}
          selectedItem={period}
        />
      </div>
    </div>
  )
}
