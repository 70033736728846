import { isNil } from 'lodash';

export enum AttachmentTypes {
  Video = 'Video',
  Photo = 'Photo',
}

/**
 * Параметр для всех открываемых вложений полёта в режиме просмотра
 */
export const URL_PARAM_ATTACHMENT_ID = 'attachmentId';

/**
 * Параметр для перехода к конкретному моменту видео (в секундах)
 */
export const URL_PARAM_VIEWTIME = 'time';

/**
 * Функция для генерации параметров ссылки при открытии вложения полёта
 * @param attachmentId - идентификатор вложения, для которого будет сгенерированы параметры ссылки
 * @param time - время просмотра видео вложения (в секундах)
 * @returns - параметры ссылки для открытия вложения
 */
export const getAttachmentLinkParams = (attachmentId: string, time?: number | null): string => {
  const url = new URLSearchParams();

  url.set(URL_PARAM_ATTACHMENT_ID, attachmentId);

  if(!isNil(time)) {    
    url.set(URL_PARAM_VIEWTIME, time.toString());
  }

  return `?${url.toString()}`;
};
