import _ from 'lodash';
import { Feature, Map as OLMap, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { Control, defaults } from 'ol/control';
import OSM from 'ol/source/OSM';
import {
  LayerOptions,
  CHANGE_ZOOM_DURATION,
  CHANGE_ZOOM_STEP,
  EMPTY_DEFAULT_CONTROLS_MAP,
  MapEngine, MapsDocument
} from './interfaces';
import LayerGroup from 'ol/layer/Group';
import { getBaseCenter } from '@/shared/map/lib';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { FeatureLike } from 'ol/Feature';
import { Draw, Interaction } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Point } from 'ol/geom';
import { defineStyle } from './markers';
import { MapMarker } from '../model/markers/interface';
import { Coordinates, Location } from '@workspace/4Z1.ts.utils';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Pixel } from 'ol/pixel';
import BaseLayer from 'ol/layer/Base';
import { Layers } from './layers';
import { Layer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import TileSource from 'ol/source/Tile';
import { mapCoordinates } from '@/shared/map';
import { logger } from '@workspace/4Z1.ts.utils';

const log = logger('MAP_CORE');

/**
 * Время плавной центровки камеры карты на новую точку
 */
const MAP_CENTER_ANIMATION_DURATION_MS = 150;

/**
 * Класс ядра компонента карты
 */
export class MapCore implements MapEngine {
  /** Карта */
  private _map!: OLMap;
  /** Базовые координаты карты */
  private baseCenter = getBaseCenter();
  /** Список источников карты */
  private sources: Map<string, VectorSource> = new Map();

  /** Представление карты */
  private _view: View = new View({
    center: mapCoordinates(this.baseCenter),
    zoom: 10,
  });

  private _osmLayer = new TileLayer({
    source: new OSM(),
  });

  constructor(
    private readonly _id: string = 'map',
  ) {
    this.initialOpenLayerMap();
  }

  get osmLayer() {
   return this._osmLayer;
  }

  get view() {
    return this._map.getView();
  }

  /**
   * Обработчик события движения мыши на карте
  */
  public onPointerMove(callback: (e: MapBrowserEvent<UIEvent>) => void):  void {
    this._map.on('pointermove', callback);
  }

  /**
   * Функция отписки от события движения мышью
  */
  public offPointerMove(callback: (e: MapBrowserEvent<any>) => void): void {
    this._map.un('pointermove', callback);
  }

  /**
    * Подписка на событие клика на карте
  */
  public onMapClick(callback: (e: MapBrowserEvent<UIEvent>) => void): void {
    this._map.on('click', callback);
  }

  /**
    * Отписка от события клика на карте
  */
  public offMapClick(callback: (event: MapBrowserEvent<any>) => void) {
    this._map.un('click', callback);
  }

  /**
   * Проверяет, есть ли фича в точке
  */
  public hasFeatureAtPixel(pixel: Pixel): boolean {
    return this._map.hasFeatureAtPixel(pixel);
  }

  /**
   * Выполняем действие для каждой фичи в точке
  */
  public forEachFeatureAtPixel(pixel: Pixel, callback: (feature: FeatureLike) => void): void {
    this._map.forEachFeatureAtPixel(pixel, callback);
  }

  /**
   * Инициализации карты
   */
  private initialOpenLayerMap() {
    this._map = new OLMap({
      layers: [this._osmLayer],
      view: this._view,
      controls: defaults(EMPTY_DEFAULT_CONTROLS_MAP),
    });
  }

  /**
   * Внедрения карты в DOM дерево
   */
  public init() {
    this._map.setTarget(this._id);
  }

  /**
   * Установить слой базовой карты
   */
  public setBaseMapLayer(layer: BaseLayer) {
    // Отключаем видимость у всех слоев с картами, кроме выбранной
    this._map
      .getLayers()
      .getArray()
      .find((layer) => layer?.get('name') === Layers.Maps)?.get('layers')
      .getArray()
      .forEach((baseMap: Layer) => {
        baseMap.get('map_id') === layer.get('map_id')
          ? baseMap.setVisible(true)
          : baseMap.setVisible(false)
      });

  }

  /**
   * Центрирование карты
   */
  public setCenter(center: Coordinates, animate = false) {
    const view = this._map.getView();
    const coordinates = mapCoordinates(center);

    if (animate) {
      view.animate({
        center: coordinates,
        duration: MAP_CENTER_ANIMATION_DURATION_MS,
      });

      return;
    }

    this._map.getView().setCenter(coordinates);
  }

  /**
   * Добавление нового контрола на карту
   */
  public addControls(control: Control) {
    this._map.getControls().extend([control]);
  }

  /**
   * Установка вида отображения
   */
  setView(view: View) {
    this._map.setView(view);
  }

  /**
   * Добавление нового слоя
   * @description Если слой с таким именем уже существует, то ничего не произойдет.
   * @param layer - слой карты
   */
  public addLayer(layer: LayerGroup) {

    if (_.isNil(layer.get('name'))) {
      log.error('Попытка добавить слой без параметра "name". Для корректной работы, добавьте параметр "name" к слою!');
      throw new Error('Слой передан без параметра "name"!')
    }

    if(this.checkLayerExists(layer.get('name'))) {
      return;
    }

    this._map.addLayer(layer);
  }

  /**
   * Добавляет группу слоев на карту.
   */
  public addLayerGroups(layers:BaseLayer[], options = {}) {
    this._map.addLayer(new LayerGroup({ layers, ...options }));
  }

  /**
   * Возвращает коллекцию слоев, связанных с картой.
   */
  public getLayers() {
    return this._map.getLayers().getArray();
  }

  /**
   * Проверка на существование слоя на карте.
   * @param layerName - наименование слоя
   */
  private checkLayerExists(layerName: string): boolean {
    return this.getLayers().some(item => item.get('name') === layerName);
  }

  public getLayerByName(name: string, layers = this.getLayers()): BaseLayer | undefined {
    if (layers.find(item => item.get('name') === name)) {
      return layers.find(item => item.get('name') === name);
    }

    for (let layer of layers) {
      if (layer instanceof LayerGroup && layer.getLayers().getArray().length > 0) {
        const result = this.getLayerByName(name, layer.getLayers().getArray());
        if (!_.isNil(result)) {
          return result;
        }
      }
    }

    return undefined;
  }

  /**
   * Удаление слоя с карты по имени
   */
  public removeLayerByName(name: string) {
    this.getLayers().forEach((layer: any) => {
      if (layer.get('name') === name) this._map.removeLayer(layer);
    });
  }

  /**
   * DOM элемент в котором интегрирована Карта
   */
  get container(): HTMLElement {
    return this._map.getViewport();
  }

  /**
   * Возвращает положение пикселя по которому был произведен клик
   */
  private getEventPixel(event: UIEvent): Pixel {
    return this._map.getEventPixel(event);
  }

  /**
   * Возвращает географические координаты точки, по которой был сделан клик
   */
  public getCoordinateFromPixel(pixel: Pixel): Coordinates | undefined {
    const coordinates = this._map.getCoordinateFromPixel(pixel);

    return Location.parse(toLonLat(coordinates).toReversed());
  }

  /**
   * Значение текущего зума
   */
  get zoom(): number {
    return this._map.getView().getZoom() as number;
  }

  /**
   * Метод приближение зума
   */
  public zoomIn() {
    this._map.getView().animate({
      zoom: this.zoom + CHANGE_ZOOM_STEP,  duration: CHANGE_ZOOM_DURATION
    });
  }

  /**
   * Метод отдаление зума
   */
  public zoomOut() {
    this._map.getView().animate({
      zoom: this.zoom - CHANGE_ZOOM_STEP,  duration: CHANGE_ZOOM_DURATION
    });
  }

  /**
   * Подписка клика на фичуу
   */
  public onClickFeature(target: string, callback: (feature: FeatureLike) => void): (e: MapBrowserEvent<UIEvent>) => void {
    // Сохранение метода для обработчика клика
    const eventCallback = (e: MapBrowserEvent<UIEvent>) => {
      this._map.forEachFeatureAtPixel(e.pixel,  (feature: FeatureLike) => {
        if (feature && feature.get(target)) callback(feature);
      });
    }

    // Подписка метода на событие клик
    this._map.on('click', eventCallback);

    // Возвращаем метод для обработчика клика.
    // Чтобы потом можно было от него отписаться, при помощи метода offClickFeature.
    return eventCallback;
  }

  /**
   * Добавление взаимодействий с картой
   */
  public addInteraction(interaction: Interaction | Draw) {
    this._map.addInteraction(interaction);
  }

  /**
   * Удаление взаимодействий с карты
   */
  public removeInteraction(interaction: Interaction | Draw) {
    this._map.removeInteraction(interaction);
  }

  /**
   * Добавление маркера на карту
   * @param data - Данные маркера
   * @description При добавлении маркера, слой создается автоматически.
   * При использовании данного метода, не забывай удалять слой с карты пир помощи метода removeLayerByName
   * по параметру data.layerID. В момент уничтожения компонента из DOM дерева.
   */
  public addMarker(data: MapMarker): void {
    const source = this.getSource(data.layerId);
    const marker = this.createMarker(data);
    source.addFeature(marker);
  }

  /**
   * Удаление маркера с карты
   * @param data - Данные маркера
   */
  public deleteMarker(data: MapMarker): void {
    const source = this.getSource(data.layerId);
    source.removeFeature(source.getFeatureById(data.id) as Feature);
  }

  /**
   * Очищение слоя карты
   * @param layerId - Идентификатор слоя
   */
  public clearLayer(layerId: string) {
    const source = this.getSource(layerId);
    source.clear();
  }

  /**
   * Добавление маркера на карту
   * @param name - имя слоя
   * @param source - источник слоя
   */
  private createLayer(name: string, source: VectorSource) {
    return new VectorLayer({
      properties: { name },
      source: source,
      zIndex: 20, // TODO сделать возможным аджастить zIndex
    })
  }

  /**
   * Получение источника слоев на карте
   * @param layerId - идентификатор слоя
   */
  private getSource(layerId: string): VectorSource {
    const source = this.sources.get(layerId);

    if (source !== undefined) {
      return source;
    }

    const newSource = new VectorSource();
    const layer = this.createLayer(layerId, newSource);
    layer.set('name', layerId);

    this._map.addLayer(layer);
    this.sources.set(layerId, newSource);

    return newSource;
  }

  /**
   * Создание на карту
   * @param data - Данные маркера
   */
  private createMarker(data: MapMarker): Feature {
    const { location, type: markerType, id } = data;
    const point = new Point(fromLonLat([location.lon, location.lat]));

    const style = defineStyle(markerType);
    const marker = new Feature(point);
    marker.setStyle(style);
    marker.setId(id);

    return marker;
  }


  /**
   * Получение фичи по событию клика мыши на карте
   */
  public getFeatureByUIEvent(event: UIEvent, param: string, value: string): FeatureLike | undefined {
    const features = this.getFeaturesByUiEvent(event);
    return features.find(feature => feature.get(param) === value);
  }

  /**
   * Получение списка фич по событию браузера
   */
  public getFeaturesByUiEvent(event: UIEvent): FeatureLike[] {
    const pixels = this.getEventPixel(event);
    return this._map.getFeaturesAtPixel(pixels);
  }

  /**
   * Координаты в формате строки по клику
   */
  public getCoordinatesByUIEvent(event: UIEvent): Coordinates | undefined {
    return this.getCoordinateFromPixel(this.getEventPixel(event));
  }

  /**
   * Создание базового слоя с картой (OSM, Google, Yandex). Которые загрузил пользователь.
   * @param layer
   * @param visible
   */
  public createMapLayer(layer: MapsDocument, visible = false): TileLayer<TileSource>  {
    const {id, name, url} = layer;
    return new TileLayer({
      title: name,
      map_id: id,
      visible,
      source: new XYZ({url}),
    } as LayerOptions)
  }

  public getOverlays() {
    return this._map.getOverlays();
  }

  public addOverlay(overlay: Overlay) {
    this._map.addOverlay(overlay);
  }

  public getOverlayById(id: string | number): Overlay {
    return this._map.getOverlayById(id);
  }

  public clearOverlaysList(listIds: Set<string>) {
    listIds.forEach(overlayId => this._map.removeOverlay(this._map.getOverlayById(overlayId)));
  }

  public removeOverlay(overlay: Overlay): void {
      this._map.removeOverlay(overlay);
  }
}
