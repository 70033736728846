import { Tooltip, TriangleIcon } from '@workspace/4Z1.uikit.react';
import { Button } from '@workspace/4Z1.uikit.react';
import classNames from 'classnames';
import { FC, RefObject, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';

import ArtilleryTriangle from '@/components/Map/MapPanel/ArtilleryTriangle';
import { dep, diInject } from '@/HOC';
import { getOlMap, implicitMapCheck } from '@/shared/map';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { Portal } from '@/shared/utils';

import { ArtilleryTriangleStore } from '../model/artilleryTriangle.store';

interface Props extends MapPluginProps {
  readonly target?: RefObject<Element>;
  readonly store: ArtilleryTriangleStore;
}

const ArtilleryTriangleLayer: FC<Props> = ({
  map = implicitMapCheck(),
  target,
  store,
}) => {
  const intl = useIntl();
  const [cookies] = useCookies(['reprojection']);

  useEffect(() => {
    return () => {
      store.unmount();
    };
  }, []);

  if (!store.enabled) {
    return;
  };

  return (
    <>
      <Portal target={target}>
        <Tooltip
          text={intl.formatMessage({ id: 'map.Measure.triangle' })}
          direction="bottom-center"
        >
          <Button
            onlyIcon
            icon={<TriangleIcon />}
            onClick={() => store.toggleVisibility()}
            className={classNames({ active: store.visibility })}
          />
        </Tooltip>
      </Portal>
      <ArtilleryTriangle
        btnAT={store.visibility}
        map={getOlMap(map)}
        cookies={cookies}
      />
    </>
  );
};

export default diInject(ArtilleryTriangleLayer, {
  store: dep(ArtilleryTriangleStore.diKey),
});
