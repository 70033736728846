import { makeAutoObservable } from 'mobx';

class RequestHandler<Response, Error = unknown> {
  isLoading: boolean = false;
  data: Response | undefined = undefined;
  error: Error | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  private reset() {
    this.isLoading = false;
    this.data = undefined;
    this.error = undefined;
  }

  async handleRequest(requestFn: () => Promise<Response | undefined>) {
    this.reset();

    try {
      this.isLoading = true;
      const response = await requestFn();
      this.data = response;

      return response;
    } catch (error) {
      this.error = error as Error;

      return Promise.reject(error);
    } finally {
      this.isLoading = false;
    }
  }
}

export default RequestHandler;
