import { type RestApi } from '@/shared/api/rest';
import urlJoin from 'url-join';
import { injectable } from 'inversify';
import { createRestApi } from '@/shared/api/rest';

export interface UsbFileUpload {
  readonly name: string;
  readonly size: number;
  readonly bytesUploaded: number;
}

export interface UsbUploadApi {
  start(loadId: string): Promise<void>;
  updateStatus(loadId: string): Promise<readonly UsbFileUpload[]>;
}

@injectable()
export class UsbUploadApiImpl implements UsbUploadApi {

  private static readonly API_BASE = API;

  private static readonly PATHS = {
    start: urlJoin(UsbUploadApiImpl.API_BASE, 'files', 'upload'),
    status: urlJoin(UsbUploadApiImpl.API_BASE, 'files', 'check'),
  } as const;

  constructor(
    private readonly client: RestApi = createRestApi(),
  ) { }

  start(loadId: string): Promise<void> {
    return this.client.post(UsbUploadApiImpl.PATHS.start, undefined, { loadId });
  }

  updateStatus(loadId: string): Promise<readonly UsbFileUpload[]> {
    return this.client.get(UsbUploadApiImpl.PATHS.status, { loadId })
      .then(resp => {
        return resp.data.files.map(file => {
          return {
            name: file.filename ?? 'n/a', // TODO
            size: file.total,
            bytesUploaded: file.uploaded,
          }
        });
      });
  }

}
