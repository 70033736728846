export enum Layers {
  Maps = 'maps',
  Vprf = 'vprf',
  Traffic = 'traffic',
  Routes = 'routes',
  Tiles = 'tiles',
  Ofps = 'ofps',
  Flights = 'flights',
  Photos = 'photos',
  Videos = 'videos',
  Ofp = 'ofp',
  Ofp_compare = 'ofp_compare',
  Violations = 'violations',
  Zviolations = 'zviolations',
  Marks = 'marks',
  Sk42grid = 'sk42grid',
  Onlines = 'onlines_global',
}
/** Слой отвечающий за отображение видимой зоны Совы */
export const FIELD_OF_VIEW_LAYER = 'fieldOfViewLayer';

export const DEFAULT_MAP_LAYERS = [Layers.Marks, Layers.Routes, Layers.Tiles, Layers.Onlines, Layers.Flights, Layers.Violations, Layers.Ofps, FIELD_OF_VIEW_LAYER];

export const OSM_MAP_ID = -1;

export enum LayersFlags {
  /**
   * Определяет отображение/скрытие вложенных элементов слоя на панели слоев карты
   * 
   * Важно! - Используется на старых слоях, чтобы скрыть возможность открытия/закрытия группы слоёв в дропдауне на панели слоёв
   * 
   * Убрать, когда все старые слои, обернутые в LayerGroup, будут переписаны
   * 
   * @type {(boolean)}
  */
  DisableChildrenVisibilityChange = 'disable-children-visibility-change',
}
