import { UrqlClientProvider } from '@/services/UrqlClientProvider';
import { Fragment, PropsWithChildren } from 'react';
import { Inspector } from 'react-dev-inspector';
import { Outlet } from 'umi';
import Lisence from '@/components/Lisence';
import OnlineNotification from '@/components/OnlineNotification';
import { AppContext, StoreContext, useStore } from '@/shared/store/context';
import { RootAppContext } from '@/shared/store/rootAppContext';
import { Sidebar } from '@/widgets/Sidebar';
import routes from '../../config/routes';
import { isDemoMode } from '@/shared/utils/isDemoMode';
import { RawIntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import { ConfigProvider } from 'antd';
import { useIntlSetup } from '@/shared/hooks';
import { withProvider } from '@/HOC';
import GlobalContainer from '@/shared/di/global.module';
import { AppLicense } from '@/widgets';
import { GLOBAL_OLD_FASHIONED_STORE } from '@/shared/di/old.fashioned';

const InspectorWrapper =
  process.env.NODE_ENV === 'development' ? Inspector : Fragment;
const store = GLOBAL_OLD_FASHIONED_STORE;
const appContext = new RootAppContext();
const DEMO = isDemoMode();

const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StoreContext.Provider value={store}>
      <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
    </StoreContext.Provider>
  );
};

const AntdConfigLayout: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const { localeStore } = useStore();

    return (
      <ConfigProvider locale={localeStore.libLocales?.antd}>
        {children}
      </ConfigProvider>
    );
  },
);

const LocaleLayout: React.FC<PropsWithChildren> = observer(({ children }) => {
  const intl = useIntlSetup();

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
});

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseLayout>
      <AntdConfigLayout>
        <LocaleLayout>
          <InspectorWrapper>
            <UrqlClientProvider>
              <Lisence>
                <OnlineNotification>
                  {DEMO ? (
                    <div className="sidebarOutlet">
                      <Sidebar
                        routes={routes[0].routes}
                        footer={
                          <AppLicense/>
                        }
                      />
                      {children ?? <Outlet />}
                    </div>
                  ) : (
                    children ?? <Outlet />
                  )}
                </OnlineNotification>
              </Lisence>
            </UrqlClientProvider>
          </InspectorWrapper>
        </LocaleLayout>
      </AntdConfigLayout>
    </BaseLayout>
  );
};

export default withProvider(Layout, GlobalContainer);
//export default UserService.initKeycloak(Layout);
