import { FC, useState } from 'react';
import { Assets, AssetTypes } from '@/entities/assets/model';
import { useIntl } from 'react-intl';
import { Button, EditIcon, Tooltip } from '@workspace/4Z1.uikit.react';
import { AssetCardProps } from '@/widgets';
import Modal from './Modal';
import { isAfuMode } from '@/shared/utils/isAfuMode';

const ChangeCoordinates: FC<AssetCardProps> = ({ asset }) => {
  const intl = useIntl();
  const afu = isAfuMode();
  const [visible, setVisible] = useState<boolean>(false);

  if (!Assets.canUpdateCoordinates(asset)) {
    return <></>;
  }

  return (
    <>
      <Tooltip
        className="changeCoordinatesTooltip"
        text={intl.formatMessage({
          id: 'online.assetCard.tooltip.coordinates',
        })}
      >
        <Button
          className="changeCoordinatesButton"
          type="badge"
          size="small"
          iconPosition="left"
          icon={<EditIcon.Small />}
          onClick={() => setVisible((state) => !state)}
          label={intl.formatMessage({ id: 'online.assetCard.coordinates' })}
        />
      </Tooltip>

      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        asset={asset}
      />
    </>
  );
};

export default ChangeCoordinates;
