import { isNil } from 'lodash';
import { type TreeData } from '@workspace/4Z1.uikit.react';
import { MapEngine } from '@/shared/map/model/interfaces';
import { Layers, LayersFlags } from '@/shared/map';
import TileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';

type Layer = BaseLayer | TileLayer<TileSource> | LayerGroup;

export interface ItemLayer<T> {
  key: string;
  label: string;
  checked: boolean;
  open?: boolean;
  data?: T;
  neutral?: boolean;
  children?: ItemLayer<T>[];
}

export const getBaseLayersMap = (map: MapEngine): BaseLayer[] => {
  return map.getLayers().find((layer) => layer.get('name') === Layers.Maps)?.getLayersArray() ?? [];
}

export const getLayersMap = (map: MapEngine, layers: Layers[]): TreeData<Layer>[] => {
  return map.getLayers().flatMap(layer => {
    if (layers.includes(layer.get('name'))) {
      return getLayer(layer);
    }
    return [];
  }).sort((a,b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

}

const getLayer = (layer: Layer): ItemLayer<Layer> => {
  const shouldHideChildren: boolean = layer.get(LayersFlags.DisableChildrenVisibilityChange);

  const item: ItemLayer<Layer> = {
    key: layer.get('name'),
    label: layer.get('title'),
    checked: Boolean(layer.getVisible()),
    data: layer,
    open: false,
  };

  if (shouldHideChildren !== true && layer instanceof LayerGroup) {
    const layerGroups = layer.getLayers().getArray();

    item.children = [];
    item.neutral = true;
    layerGroups.map((layer) => {
      if (!isNil(layer.get('name'))) {
        item.children?.push(getLayer(layer));
      }
    });
  }

  return item;
};