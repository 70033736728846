import './index.css';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { CloseSquareOutlined } from '@ant-design/icons';

import { XYZ } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import { GroupLayerOptions } from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group';
import { Feature, Map, Overlay, TileCache } from 'ol';
import { getArea } from 'ol/sphere';

import VPRF_CTRZ from './ctrz';
import VPRF_SECZ from './secz';
import VPRF_LZ from './lz';
import VPRF_EZ from './ez';
import VPRF_DZ from './dz';
import VPRF_NOTAM from './notam';
import VPRF_RO from './ro';
import VPRF_MDP from './mdp';
import VPRF_RPI from './rpi';


class T_VPRF extends Component<any, any> {
  map: Map = this.props.map;
  cache    = new TileCache();
  source   = new XYZ();
  minZoom  = 1;
  maxZoom  = 21;
  group_vprf = new LayerGroup();

  urlApi   = VPRF + `/fpln`;
  urlTiles = '?tiles=1:1:3,2:1:3,1:2:3,2:2:3,0:0:3,1:0:3,2:0:3,3:0:3,0:1:3,3:1:3,0:2:3,3:2:3,0:3:3,1:3:3,2:3:3,3:3:3,4:2:3,5:2:3,6:2:3,4:1:3,5:1:3,6:1:3,7:1:3,7:2:3,4:3:3,5:3:3,6:3:3,7:3:3';
  urlInfo  = '/info/zone?index';

  popup: any = React.createRef();
  overlay    = new Overlay({
    id: 'vprf_overlay',
    autoPan: {
      animation: {
        duration: 250
      }
    }
  });

  constructor(props: any) {
    super(props);

    this.state = {
      layers: [],
      feature: new Feature(),
    };

    const { intl } = this.props;
    
    const mainXYZ = new TileLayer({
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      source: this.source
    });
    
    this.group_vprf = new LayerGroup({
      title: intl.formatMessage({
        id: 'layer.vprf',
        defaultMessage: 'Airspace',
      }),
      fold: 'close',
      name: 'vprf',
      className: 'vprf',
      layers: [mainXYZ],
    } as GroupLayerOptions);
  }

  componentDidMount() {
    this.overlay.setElement(this.popup.current);
    this.props.map.addLayer(this.group_vprf);
    this.props.map.addOverlay(this.overlay);

    let _z = '';
    let coordTiles: string[] = [];
    this.map.on('moveend', (e: any) => {
      coordTiles = [];
    });
    
    this.source.setTileUrlFunction((tileCoord) => {
      var z = tileCoord[0].toString();
      if (parseInt(z) >= this.minZoom && parseInt(z) <= this.maxZoom){
        if (_z != z) coordTiles = [];
        var x = tileCoord[1].toString();
        var y = tileCoord[2].toString();
        coordTiles.push(`${x}:${y}:${z}`);
        _z = z;
      }
      
      this.source.set('coordTiles', coordTiles.join(','));
      return '';
    });

    this.map.on('click', (e) => {
      let _feature: any = new Feature();
      let minArea: number = Number.MAX_VALUE;
      this.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
        if (feature && feature.get('typeClick') === 'vprf'){
          const area = getArea(feature.getGeometry());
          if (area < minArea) {
            minArea  = area;
            _feature = feature;
            _feature.set('coord', e.coordinate);
          }
        }
      });

      this.setState({feature: _feature});

      if (!_feature.getId()) this.overlay.setPosition(undefined);
    });
    
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    //this.source.clear();
    //this.removeLayer('vprf');
    this.props.map.removeLayer(this.group_vprf);
    this.props.map.removeOverlay(this.overlay);
  }

  setHtml = (coord: number[], html: string) => {
    this.overlay.setPosition(coord);
    const content = document.getElementById('vprf_popup-content');
    if (content) content.innerHTML = html;
  };

  addLayer = (layer: any) => {
    const layers = this.group_vprf.getLayers();
    layers.push(layer);
    this.group_vprf.setLayers(layers);
  }

  popupClose = () => {
    this.overlay.setPosition(undefined);
    return false;
  };

  removeLayer = (layer_name: string) => {
    this.map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) this.map.removeLayer(layer);
    });
  };

  render() {

    return (
      <>
        <VPRF_CTRZ map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_SECZ map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_LZ map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_EZ map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_DZ map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_NOTAM map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_RO map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_MDP map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <VPRF_RPI map={this.map} urlApi={this.urlApi} urlTiles={this.urlTiles} urlInfo={this.urlInfo} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <div id="vprf_popup" className="ol-popup" ref={this.popup} >
          <div className="ol-popup-closer">
            <a onClick={() => this.popupClose()} id="popup-closer"><CloseSquareOutlined /></a>
          </div>
          <div id="vprf_popup-content"></div>
        </div>
      </>
      
    );
  }
}

export default injectIntl(T_VPRF);
