import { type FC, RefObject, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, ClearIcon, GatewayIcon, RulerIcon } from '@workspace/4Z1.uikit.react';

import { MapPluginProps } from '@/shared/map/model/interfaces';
import { implicitMapCheck } from '@/shared/map';
import { Portal } from '@/shared/utils';

import { MeasureStore } from '../model/Measure.store';
import { DrawMeasureType, LAYER_NAME } from '../model/MeasureStyle';
import classNames from 'classnames';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';


interface Props extends MapPluginProps {
  readonly ruleTarget?: RefObject<Element>;
  readonly areaTarget?: RefObject<Element>;
  readonly clearTarget?: RefObject<Element>;
}

/**
 * Плагин мер на карте.
 * @param ruleTarget - Ссылка (useRef) - на место расположения кнопки "Расстояние". При его отсутствии кнопка будет скрыта.
 * @param areaTarget - Ссылка (useRef) - на место расположения кнопки "Площадь". При его отсутствии кнопка будет скрыта.
 * @param clearTarget - Ссылка (useRef) - на место расположения кнопки "Очистить". При его отсутствии кнопка будет скрыта.
 */
export const Measure: FC<Props> = observer((
  {
    map = implicitMapCheck(),
    ruleTarget,
    areaTarget,
    clearTarget,
  }) => {
  const intl = useIntl()
  const [store] = useState(() => new MeasureStore(map));

  useEffect(() => {
    return () => {
      map.removeLayerByName(LAYER_NAME);
    }
  }, [map]);

  return <>
    {/* Расстояние */}
    <Portal target={ruleTarget}>
      <Tooltip text={intl.formatMessage({ id: 'report.length' })} direction='bottom-center'>
        <Button
          onlyIcon
          icon={<RulerIcon/>}
          onClick={() => store.startDraw(DrawMeasureType.LineString)}
          className={classNames({active: store.type === DrawMeasureType.LineString})}
        />
      </Tooltip>
    </Portal>

    {/* Площадь */}
    <Portal target={areaTarget}>
      <Tooltip text={intl.formatMessage({ id: 'map.Measure.area' })} direction='bottom-center'>
        <Button
          onlyIcon
          icon={<GatewayIcon/>}
          onClick={() => store.startDraw(DrawMeasureType.Polygon)}
          className={classNames({active: store.type === DrawMeasureType.Polygon})}
        />
      </Tooltip>
    </Portal>

    {/* Очистить */}
    <Portal target={clearTarget}>
      <Tooltip text={intl.formatMessage({ id: 'map.Measure.clear' })} direction='bottom-center'>
        <Button
          onlyIcon
          icon={<ClearIcon/>}
          onClick={() => store.endDraw()}
        />
      </Tooltip>
    </Portal>
  </>
})
