import { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style } from 'ol/style';
import { PhotoAttachment } from '@/entities/attachment';
import { Flight, Photo } from '@/.graphql/graphql';
import BaseLayer from 'ol/layer/Base';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import _ from 'lodash';
import {
  getTrackLineStyle,
  getWaypointStyle,
  WaypointType,
} from '@/entities/assetTrack';
import { FeaturesType } from '@/entities/features';

interface Props extends MapPluginProps {
  readonly photo?: PhotoAttachment;
  readonly flight: Flight;
  readonly data: Photo[];
  readonly searcharealink?: string;
  readonly highlightedPhoto?: PhotoAttachment;
  readonly customLink?: (photo: Photo) => void;

  // TODO - Понять, что это за тип
  readonly searchArea?: object | string;
}

export const FlightTrackLayer: FC<Props> = ({
  map = implicitMapCheck(),
  data,
  flight,
  photo,
  customLink,
  highlightedPhoto,
  ...props
}) => {
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);

  const _title = intl.formatMessage({
    id: 'module.imagetiles',
    defaultMessage: 'Photos',
  });

  const setWaypointFeature = (
    feature: Feature,
    photo: Photo,
    type: WaypointType,
  ): void => {
    let waypoint: Style = getWaypointStyle(WaypointType.Idle);
    let text: string = photo.name;

    switch (type) {
      case WaypointType.Idle:
        waypoint = getWaypointStyle(WaypointType.Idle);
        break;
      case WaypointType.Highlight:
        waypoint = getWaypointStyle(WaypointType.Highlight);
        break;
      case WaypointType.Start:
        waypoint = getWaypointStyle(WaypointType.Start);
        text = intl.formatMessage({
          id: 'photo.start',
          defaultMessage: 'Start',
        });
        break;
      case WaypointType.End:
        waypoint = getWaypointStyle(WaypointType.End);
        text = intl.formatMessage({ id: 'photo.end', defaultMessage: 'End' });
        break;
    }

    feature.setStyle(waypoint);
    feature.set('name', text);
  };

  useEffect(() => {
    // TODO - Добавить чистку слоев, когда вложение не пришло
    if (data && flight?.geometry?.geom) {

      // TODO - Добавить очистку единичных элементов, а не перебирать все слои
      removeLayer('photos');

      const line = new GeoJSON()?.readFeature(flight.geometry.geom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }) as Feature;

      line.setStyle(getTrackLineStyle());

      const source = new VectorSource({
        features: [line],
      });

      if (props.searchArea && props.searchArea.type) {
        const searchArea = new GeoJSON().readFeature(props.searchArea, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }) as Feature;

        source.addFeature(searchArea);
      }

      const lastIndex = data.length - 1;

      data.forEach(
        (photo) => {
          if (!photo?.center) return;
          const isFirst = data[0].id == photo.id && !props.searcharealink;
          const isLast =
            data[lastIndex].id == photo.id && !props.searcharealink;
          const isHighlighted = highlightedPhoto?.id === photo?.id;

          const feature = new GeoJSON().readFeature(photo.center.geom, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }) as Feature;

          feature.set('singleTab', true);
          feature.set('type', FeaturesType.LAYER_POINT);
          feature.set('className', 'layer-photo-' + photo.id);

          feature.set(
            'link',
            customLink?.(photo) ??
              '/photos/' +
                photo.flight_id +
                '?id=' +
                photo.id +
                (props.searcharealink ?? ''),
          );

          const waypointType = _.cond([
            [() => isHighlighted, () => WaypointType.Highlight],
            [() => isFirst, () => WaypointType.Start],
            [() => isLast, () => WaypointType.End],
            [() => !isLast && !isFirst, () => WaypointType.Idle],
          ]);

          setWaypointFeature(feature, photo, waypointType());

          source.addFeature(feature);
        },
        [photo, highlightedPhoto],
      );

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.flights ? true : false,
        zIndex: 2,
      } as BaseLayerOptions);

      const group_photos = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'photos',
        className: 'photos',
        layers: [layer],
      } as GroupLayerOptions);

      map.addLayer(group_photos);
    }
  }, [data, highlightedPhoto, photo]);

  // TODO - Часто повторяется, убрать
  function removeLayer(layer_name: string) {
    map.getLayers().map((layer: BaseLayer) => {
      if (layer.getClassName() === layer_name)
        map.removeLayerByName(layer_name);
    });
  }

  useEffect(() => {
    return () => {
      removeLayer('photos');
      removeLayer('drone');
    };
  }, []);

  return <></>;
};
