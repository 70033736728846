import './styles.scss';

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog } from '@workspace/4Z1.uikit.react';
import { LayersPanelStore } from '../model/LayersPanel.store';
import { LayersPanelMaps } from './LayersPanelMaps';
import { LayersPanelList } from './LayersPanelList';
import { LayersPanelPosition } from '../model/interfaces';

interface Props {
  readonly store: LayersPanelStore;
  readonly parentTarget: HTMLElement;
  readonly position?: LayersPanelPosition;
}

const HEIGHT_DIALOG = 620;
const WIDTH_DIALOG = 390;
const MARGIN_TOP_DIALOG = 15;
const MARGIN_LEFT_DIALOG_TOP_RIGHT = 210;
const MARGIN_LEFT_DIALOG_TOP_LEFT = -20;

export const LayersPanelDialog: FC<Props> = observer(
  ({ store, parentTarget, position }) => {
    return (
      <Dialog
        className="layersPanel"
        defaultOptions={{
          x:
            parentTarget.offsetLeft -
            (position === LayersPanelPosition.TopRight
              ? MARGIN_LEFT_DIALOG_TOP_RIGHT
              : MARGIN_LEFT_DIALOG_TOP_LEFT),
          y: parentTarget.offsetHeight + MARGIN_TOP_DIALOG,
          width: WIDTH_DIALOG,
          height: HEIGHT_DIALOG,
        }}
        disableDragging={true}
        minHeight={620}
        enableResizing={false}
      >
        <div className="layersPanelContent">
          {/* Компонент выбора слоя с Картой */}
          <LayersPanelMaps store={store} />
          {/* Компонент списка соев на карте и управления ими */}
          <LayersPanelList store={store} />
        </div>
      </Dialog>
    );
  },
);
