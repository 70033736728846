export const parseErrorKey = (errorMessage: string) => {
  
  const errorMap = new Map<string | RegExp, string>([
    ['File already exists', 'ERR_409_FILE_ALREADY_EXISTS'],
    ['File type extension is not supported', 'ERR_415_FILE_TYPE_NOT_SUPPORTED'],
    ['File not transferred to operational storage.', 'ERR_500_FILE_TRANSFER_FAILED'],
    ['Not enough free space in upload and/or operative storage', 'ERR_507_NOT_ENOUGH_SPACE'],
    ['File group not added', 'ERR_500_FILE_GROUP_NOT_ADDED'],
    ['Error while checking available space for upload', 'ERR_500_SPACE_CHECK_ERROR'],
    ['Post-processing failed...', 'ERR_500_POST_PROCESSING_FAILED'],
    ['File information not found', 'ERR_404_FILE_INFO_NOT_FOUND'],
    ['Bearer user token required', 'ERR_401_BEARER_TOKEN_REQUIRED'],
    ['File information not updated', 'ERR_500_FILE_INFO_NOT_UPDATED'],
    ['File arch result information was not updated', 'ERR_500_FILE_ARCH_RESULT_NOT_UPDATED'],
    ['Internal error', 'ERR_500_INTERNAL_ERROR'],
    ['Network Error', 'ERR_400_NETWORK_ERROR'],
    ['Conflict', 'ERR_409_FILE_ALREADY_EXISTS'],
    ['400', 'ERR_409_FILE_ALREADY_EXISTS'],
    ['ERR_NETWORK', 'ERR_400_NETWORK_ERROR'],
  ]);

  for (const [key, value] of errorMap.entries()) {
    if (typeof key === 'string' && errorMessage.includes(key)) {
      return value;
    } else if (key instanceof RegExp && key.test(errorMessage)) {
      return value;
    }
  }

  return 'ERR_500_UNKNOWN_ERROR';
};