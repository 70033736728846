import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { ContextMenuStore } from '../../model/ContextMenu.store';
import { implicitContextMenuCheck } from '../../model/implicitContextMenuCheck';
import { Item } from '../Item';

interface Props {
  readonly store?: ContextMenuStore;
  readonly className?: string;
}

export const ModalMark: FC<Props> = observer(({
  store = implicitContextMenuCheck(),
  className,
}) => {
  const intl = useIntl();

  const openModal = () => {
    store.markModalOpen = true;
    store.show = false;
  }

  return (
    <Item
      className={className}
      action={() => openModal()}
      label={store.markId
        ? intl.formatMessage({id: 'mark.context.editMark'})
        : intl.formatMessage({id: 'mark.context.addMark'})}
    />
  )
})
