import {
  forwardRef,
  ForwardRefExoticComponent,
  type ReactNode,
  RefObject,
  useImperativeHandle,
  useRef
} from 'react';
import { Map, MapProps } from '../Map';
import {
  CursorCoordinatesPanel,
  MapsLayer,
  Measure,
  SearchPlugin,
  ZoomPanel,
  ZoomPosition,
  SearchOnArea,
  TilesLayer,
  RoutesLayer,
  MarkersLayer,
  ArtilleryTriangleLayer
} from '@/features/map';
import {
  AddViolation,
  cmiRemoveMark,
  ContextMenu,
  CopyCoordinates,
  ModalMark
} from '@/features/map/contextMenu';
import { LayersPanel } from '@/features/map/LayersPanel';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { FlightsByDateAndArea } from '@/widgets/FlightsByDateAndArea';
import { ClusterMenuOverlay } from '@/features/map';
import { TooltipOverlay } from '@/features/map';
import { LayersPanelPosition } from '@/features/map/LayersPanel';

export interface MapBaseRef {
  readonly zoomPanelRef: RefObject<HTMLDivElement>;
}

export interface MapBaseProps extends MapProps {
  readonly id?: string;
  readonly zoomPanelPosition?: ZoomPosition;
  readonly layersPanelPosition?: LayersPanelPosition;
  readonly mapTools?: RefObject<Element>;
  readonly targetSideBar?: RefObject<Element>;
  readonly showCursorCoordinate?: boolean;
  readonly showZoomPanel?: boolean;
  readonly showSearchPanel?: boolean;
  readonly showMeasure?: boolean;
  readonly showLayersPanel?: boolean;
  readonly showContextMenu?: boolean;
  readonly showSearchOnArea?: boolean;
  readonly showFeatureTooltips?: boolean;
  readonly showClusterMenu?: boolean;
  readonly children?: ReactNode;
  readonly mapCenter?: Coordinates;
  readonly animateCenterMove?: boolean;
  readonly addBaseLayersPage?: boolean;
  readonly zoomPanelButtonSize?: string;
  readonly showArtilleryTriangle?: boolean;
}

/**
 * Базовый шаблон Карты.
 * Содержит в себе компоненты: Поиск на карте, Координаты курсора, Зум панель, Плагин Мер
 * Все плагины опциональные.
 * @param zoomPanelPosition - позиция зум панели (принимает enum ZoomPosition)
 * @param layersPanelPosition - позиция панели слоев (принимает enum LayersPanelPosition)
 * @param showZoomPanel - позиция зум панели (принимает enum ZoomPosition)
 * @param showCursorCoordinate - флаг отображения плагина курсор координат
 * @param showSearchPanel - флаг отображения плагина поиск на карте
 * @param showMeasure - флаг отображения плагина мер на карте
 * @param showContextMenu - флаг отображения плагина контекстного меню
 * @param showLayersPanel - флаг отображения плагина слоев на карте
 * @param showSearchOnArea - флаг отображения плагина поиск на карте
 * @param mapTools - якорь для панели инструментов карты
 * @param targetSideBar - якорь левого сайдбара
 * @param addBaseLayersPage - флаг добавления базовых слоев для страницы с картой
 * @param children - можно передать дополнительные плагины в карту
 * @param showArtilleryTriangle - флаг для отображения артиллерийского треугольника
 * @param mapCenter - координаты карты
 * @param animate - флаг для смены координат центра карты с проигрыванием анимации
 */
export const Base: ForwardRefExoticComponent<MapBaseProps & React.RefAttributes<MapBaseRef>> = forwardRef(({
  id,
  zoomPanelPosition = ZoomPosition.BaseLayoutTopRight,
  layersPanelPosition = LayersPanelPosition.TopRight,
  showZoomPanel = true,
  showCursorCoordinate = true,
  showSearchPanel = true,
  showMeasure = true,
  showContextMenu = true,
  showLayersPanel = true,
  showSearchOnArea = true,
  showFeatureTooltips = true,
  showClusterMenu = true,
  addBaseLayersPage = true,
  animateCenterMove = false,
  showArtilleryTriangle = true,
  mapTools,
  targetSideBar,
  children,
  mapCenter,
  zoomPanelButtonSize,
}: MapBaseProps, ref) => {
  const zoomPanelRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => {
    return {
      zoomPanelRef
    }
  })

  return (
    <Map
      id={id}
      center={mapCenter}
      animateCenterMove={animateCenterMove}
    >
      {showClusterMenu && <ClusterMenuOverlay/>}
      {showFeatureTooltips && <TooltipOverlay/>}
      {/* Панель управления зумом карты */}
      {showZoomPanel && <ZoomPanel
        position={zoomPanelPosition}
        buttonSize={zoomPanelButtonSize}
      >
        <div ref={zoomPanelRef}></div>
      </ZoomPanel>}
      
      {/* Компонент поиска на карте */}
      {showSearchPanel && mapTools && <SearchPlugin target={mapTools} />}
      {/* Плагин поиск по выбранной области */}
      {showSearchOnArea && mapTools &&
        <SearchOnArea
          target={mapTools}
          widgetTarget={targetSideBar}
          widgetResult={<FlightsByDateAndArea />}
        />}
      {/* Плагин отображения координат курсора мыши */}
      {showCursorCoordinate && <CursorCoordinatesPanel/>}

      {/* Компонент измерений на карте. Расстояние и площадь. */}
      {showMeasure && <Measure ruleTarget={mapTools} areaTarget={mapTools} clearTarget={mapTools}/>}
      
      {/** Плагин для отображения артиллерийского треугольника на карте */}
      {showArtilleryTriangle && <ArtilleryTriangleLayer target={mapTools}/>}

      {/* Компонент отображения слоев на карте */}
      {showLayersPanel && mapTools && <LayersPanel position={layersPanelPosition} target={mapTools}/>}

      {/* Плагин контекстного меню */}
      {showContextMenu &&
        <ContextMenu items={[
          {render: <CopyCoordinates/>},
          {render: <AddViolation/>},
          {render: <ModalMark/>},
          cmiRemoveMark()
        ]}
        />
      }

      {/* Слой с картами */}
      <MapsLayer />

      {/* Обязательные слои для всех страниц */}
      {addBaseLayersPage && <MarkersLayer />}
      {addBaseLayersPage && <TilesLayer />}
      {addBaseLayersPage && <RoutesLayer />}

      {children}

    </Map>
  )
})
