export const DiKeys = {
  userOptions: Symbol.for('userOptions'),
  assetFilter: Symbol.for('assetFilter'),
  onDeviceRevoke: Symbol.for('onDeviceRevoke'),
  assetOnMap: Symbol.for('assetOnMap'),
  onAssetDirectionChange: Symbol.for('onAssetDirectionChange'),
  onAssetCoordinatesChange: Symbol.for('onAssetCoordinatesChange'),
  showVideoStream: Symbol.for('showVideoStream'),
  flightOnMap: Symbol.for('flightOnMap'),
  updateGlobalDateRange: Symbol.for('updateGlobalDateRange'),
  selectedAssetOnMap: Symbol.for('selectedAssetOnMap'),
  LocaleStore: Symbol.for('LocaleStore'),

  flightCard: {
    onVideoTrackSeek: Symbol.for('onVideoTrackSeek'),
    onVideoTimeChange: Symbol.for('onVideoTimeChange'),
    onViewtimeParamsChange: Symbol.for('onViewtimeParamsChange'),
  } as const,
  api: {
    massload: Symbol.for('massload'),
    usbupload: Symbol.for('usbupload'),
  } as const,
} as const;
