import { type FC } from 'react';
import { MarkersLayer, RoutesLayer, TilesLayer } from '@/features/map';
import { OnlinesLayer } from '@/features/map/layers/onlinesLayer';
import { Maps } from '@/shared/map';
import { MapBaseProps } from './Base';
import { OnlinesLayerDataSource } from '@/features/map/layers/onlinesLayer';

interface Props extends MapBaseProps {
  readonly searchOnArea?: any;
  readonly showContextMenu?: boolean;
  readonly showAssetName?: boolean;
  readonly dataSource: OnlinesLayerDataSource,
}

export const Onlines: FC<Props> = (
  {
    id,
    center,
    mapTools,
    targetSideBar,
    zoomPanelPosition,
    showContextMenu,
    showCursorCoordinate,
    zoomPanelButtonSize,
    showAssetName,
    children,
    dataSource,
  }) => {
  return (
    <Maps.Base
      id={id}
      mapCenter={center}
      mapTools={mapTools}
      targetSideBar={targetSideBar}
      zoomPanelPosition={zoomPanelPosition}
      showContextMenu={showContextMenu}
      showCursorCoordinate={showCursorCoordinate}
      zoomPanelButtonSize={zoomPanelButtonSize}
    >

      {/* Подключение слоев на карту */}
      <RoutesLayer/>
      <MarkersLayer/>
      <TilesLayer/>
      <OnlinesLayer
        dataSource={dataSource}
        showName={showAssetName}
      />

      {children}

    </Maps.Base>
  )
}
