import _ from 'lodash';
import { ErrorsParser } from './parser';

export interface DetailedError {
  readonly message: string;
  readonly code: string;
  readonly pointer: string;
}

/**
 * Ошибки при запросах к API. Включает как технические, так и бизнесовые ошибки.
 * Бизнесовые ошибки могут включать в себя дополнительные подробности
 *
 * @see - https://kb.eds.loc/pages/viewpage.action?pageId=34996241
 */
export class ApiError {
  constructor(
    public readonly message: string,
    public readonly code: number,
    public readonly details: readonly DetailedError[],
    public readonly source: unknown,
  ) {}

  public getErrorsByPointer(pointer: string): readonly DetailedError[] {
    return this.details.filter(error => error.pointer === pointer);
  }

  public static parse<T>(candidate: T, source: unknown): ApiError | undefined {
    return ErrorsParser.parse(candidate, source);
  }
}
export class NetworkError {
  constructor(
    public readonly message: string,
    public readonly source: unknown,
  ) {}
}
