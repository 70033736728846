import { type FC, useState } from 'react';
import { isNil } from 'lodash';
import { Button, Checkbox, ModalPopup } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { TileLayerData } from '../model/interfaces';

interface Props extends MapPluginProps {
  options: TileLayerData[];
  open: boolean;
  onClose: () => void;
}

export const TileLayerModal: FC<Props> = ({map= implicitMapCheck(), open, onClose, options}) => {
  const intl = useIntl();

  const [buttonTitle, setButtonTitle] = useState<string>(intl.formatMessage({id: 'tile.Modal.OK'}));
  const [values, setValues] = useState<string[]>([]);

  const saveChoice = () => {
    values.map(value => {
      const layer =  map.getLayerByName(value, map.getLayers());

      if (!isNil(layer)) {
        layer.setVisible(true);
      }
    });

    onClose();
  }

  const onChange = (layerId: string) => {
    setValues((prevValues) => {
      const newValues = prevValues.includes(layerId)
        ? prevValues.filter((value) => value !== layerId)
        : [...prevValues, layerId];

      // Обновляем buttonTitle на основе нового состояния values
      setButtonTitle(
        newValues.length > 0
          ? intl.formatMessage({ id: 'tile.Modal.ON' })
          : intl.formatMessage({ id: 'tile.Modal.OK' })
      );

      return newValues;
    });
  };

  return (
    <ModalPopup
      position='center'
      isOpen={open}
      onClose={onClose}
      title={
        <div className='tilesModal'>
          <div className='tilesModalTitle'>
            {intl.formatMessage({id: 'tile.Modal.Title'})}
          </div>
          <div className='tilesModalInfo'>
            *{intl.formatMessage({id: 'tile.Modal.SubTitle'})}
          </div>
        </div>
      }
      footer={
        <div className='tilesModalFooter'>
          <Button type='accent' size='small' onClick={saveChoice}>{buttonTitle}</Button>
          <Button type='badge' size='small' onClick={onClose}>Отмена</Button>
        </div>
      }
    >
      {options?.map(option => {
        return <Checkbox
          id={option.value}
          checked={values.includes(option.value)}
          onChange={(id) => onChange(id)}
          label={option.label}
        />
      })}
    </ModalPopup>
  )
}
